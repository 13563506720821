/**
 * Global Styles used across the website
 */
body {
	background-color: var(--primary-bg-color);
	color: var(--primary-fg-color);

	> div {
		overflow: hidden;
	}
}

.container {
	@include clearfix;

	width: 100%;
	margin: 0 auto;

	padding: 0 1.5rem;

	@include respond-to($tablet) {
		padding: 0 2rem;
	}

	@include respond-to($desktop) {
		max-width: var(--size-container-md);
		padding: 0;
	}

	@include respond-to($large-desktop) {
		max-width: var(--size-container-lg);
	}
}

.page {
	.page__inner {
		// @extend .container;
	}
}

.highlight {
	color: var(--link-color);
}