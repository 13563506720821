.section--contact-cta {
	color: #fff;
	background-color: #8547FF;
	margin-bottom: 2rem;
}

.contact-cta {
	text-align: center;
	padding: 2rem 0;

	.contact-cta__title {
		font-size: 2.25rem;

		@include respond-to($tablet) {
			font-size: 2.75rem;
		}
	}

	.contact-cta__cta {
		margin-top: 2rem;
	}
}