.section.homepage-locations {
	.location__list {
		margin-top: 4rem;

		display: grid;
		grid-gap: 3rem;
		grid-template-columns: 1fr;

		@include respond-to($tablet) {
			grid-template-columns: 2fr 1fr;
			grid-gap: 5rem 2rem;
			padding: 0 2rem;
		}

		.location__item	{
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 1rem;

			@include respond-to($tablet) {
				grid-template-columns: 40% 1fr;
				grid-gap: 2rem;
			}

			.location__item__image {
				position: relative;

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(0, 0, 0, 0.125);
				}

				> div {
					background-size: cover;
					background-position: left center;

					padding-top: 75%;
				}
			}

			.location__item__content {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				.location__item__content__title {
					font-size: 1.75rem;
					font-weight: bold;
				}

				.location__item__content__address, .location__item__content__contact {
					font-size: 1.25rem;
					margin-top: 0.75rem;
				}

				.location__item__content__contact {
					font-weight: 700;

					a {
						color: var(--link-color);
					}
				}
			}

			&:first-child {
				@include respond-to($tablet) {
					grid-column-start: 1;
					grid-column-end: 3;
					-ms-grid-column-span: 2;
					grid-template-columns: 50% 1fr;
					grid-gap: 4rem;
				}

				.location__item__image {
					> div {
						@include respond-to($tablet) {
							padding-top: 100%;
						}
					}
				}

				.location__item__content {
					.location__item__content__title {
						@include respond-to($tablet) {
							font-size: 2.75rem;
						}
					}

					.location__item__content__address, .location__item__content__contact {
						@include respond-to($tablet) {
							font-size: 1.75rem;
							margin-top: 1.5rem;
						}
					}
				}
			}
		}
	}
}