.page-contact {

	.section {
		padding: 2rem 0 0;

		@include respond-to($tablet) {
			padding: 4rem 0 0;
		}
	}

	.section__title {
		margin-bottom: 2rem;

		@include respond-to($tablet) {
			margin-bottom: 4rem;
		}

		.section__title__index {
			position: relative;
			font-size: 1.25rem;
			font-weight: bold;
			margin-bottom: 2.75rem;

			&:after {
				content: '';
				position: absolute;
				left: 4px;
				top: 2.25rem;
				height: 1.25rem;
				width: 2px;
				background-color: currentColor;
			}
		}
	}

	.section__content {
		font-size: 1.25rem;
		margin-top: -1rem;

		@include respond-to($tablet) {
			font-size: 1.75rem;
			margin-top: -3rem;
		}
	}

}






