.section.homepage-company {
	.company__list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;
		margin: 0 -4rem;

		.company__item {
			background-color: pink;
			height: 28rem;
		}
	}
}