// Underlines
@mixin underline($inverse: false, $size: 2px, $hasSpan: false) {
	position: relative;
	text-decoration: none !important;

	-webkit-backface-visibility: hidden;
	transition: all 0.3s;

	@if($hasSpan){
		> span{
			color: inherit;
			border-bottom: $size solid currentColor;
			transition: border-color 0.3s;
		}

		@if($inverse){
			> span{
				border-color: currentColor;
			}

			&:hover > span{
				border-color: transparent;
			}
		}@else{
			> span{
				border-color: transparent;
			}

			&:hover > span{
				border-color: currentColor;
			}
		}
	}@else{
		&:after{
			content: ' ';
			position: absolute;
			bottom: -3px;
			left: 0;
			right: 0;

			border-bottom: $size solid;
			transition: all 0.3s;

			border-color: currentColor;
		}

		@if($inverse){
			&:after{
				opacity: 1;
			}

			&:hover:after{
				opacity: 0;
			}
		}@else{

			&:after{
				opacity: 0;
			}

			&:hover:after{
				opacity: 1;
			}
		}
	}
}

@mixin underlineHover() {
	> span{
		border-color: currentColor;
	}

	&:after{
		opacity: 1;
	}
}
