.section.homepage-projects {
	.project__list {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 2rem;
		margin-top: 2rem;

		@include respond-to($tablet) {
			grid-gap: 4rem;
			margin-top: 4rem;
		}
	}

	.rotating-projects {
		position: relative;
		height: 480px;
		overflow: hidden;
		margin: 0 -0.75rem;

		@include respond-to($desktop) {
			margin: 0 -4rem;
		}

		.rotating-projects__pagination {
			display: block;
			position: absolute;
			left: 4rem;
			bottom: 3rem;
			z-index: 100;

			.rotating-projects__pagination-item {
				width: 0.875rem;
				height: 0.875rem;
				float: left;
				border-radius: 50%;
				margin-right: 0.875rem;
				background-color: #fff;

				opacity: 0.375;

				&.active {
					opacity: 1;
				}
			}
		}
	}

	.project__item {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;

		height: 100%;
		color: #fff;
		padding: 1.5rem;

		background-size: cover;
		background-position: center;

		opacity: 0;
		visibility: hidden;
		transition: all 0.75s;

		&.show {
			opacity: 1;
			visibility: visible;
		}

		@include respond-to($desktop) {
			padding: 4rem;
		}

		.project__item__title {
			font-size: 2.25rem;
			font-weight: bold;
		}
	}

	.project__list__cta	{

	}
}