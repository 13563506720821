.section.homepage-clients {
	.brand__list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;
		margin-top: 2rem;

		@include respond-to($tablet) {
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 1.5rem 3rem;
			margin-top: 4rem;
		}

		@include respond-to($desktop) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-gap: 1.5rem 4rem;
		}

		.brand__item {
			padding: 1rem;

			@include respond-to($tablet) {
				padding: 2rem;
			}

			img {
				max-width: 100%;
				transition: all 0.3s;
				//filter: grayscale(1);
				//opacity: 0.5;
			}

			//&:hover {
			//	img {
			//		filter: grayscale(0);
			//		opacity: 1;
			//	}
			//}
		}
	}

	.brand__quotes {
		position: relative;
		margin-top: 4rem;

		@include respond-to($tablet) {
			margin-top: 5rem;
			padding: 0 2rem;
		}

		&:before {
			content: '';

			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 6rem;
			background-color: #fff;
			z-index: 5;

			@include respond-to($mobile) {
				display: none;
			}
		}

		&:after {
			content: '';
			width: 3.125rem;
			height: 2.5rem;

			position: absolute;
			top: 2.5rem;
			left: 0.375rem;

			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MC4yMjgiIGhlaWdodD0iNDUuMTcxIiB2aWV3Qm94PSIwIDAgNjAuMjI4IDQ1LjE3MSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzU2MDBmZjt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSI+PHBhdGggY2xhc3M9ImEiIGQ9Ik0wLDY5Ljc3SDEyLjkwNkw0LjMsODcuODM4SDE3LjIwOGw4LjYtMTguMDY4di0yNy4xSDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Mi42NjcpIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNzAuNjY3LDQyLjY2N3YyNy4xaDEyLjkwNmwtOC42LDE4LjA2OGgxMi45MDZsOC42LTE4LjA2OHYtMjcuMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzYuMjUxIC00Mi42NjcpIi8+PC9nPjwvZz48L3N2Zz4=');
			z-index: 10;

			@include respond-to($tablet) {
				top: 4.5rem;
				left: 2.375rem;
			}
		}

		.brand__quote {
			padding: 6rem 0.375rem 3.75rem;

			@include respond-to($tablet) {
				padding: 3.75rem 3rem 3.75rem 6rem;

				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			@include respond-to($desktop) {
				padding: 3.75rem 6rem;
			}

			.brand__quote__text {
				font-size: 2.75rem;
			}

			.brand__quote__cite {
				display: flex;
				align-items: center;
				margin-top: 2.5rem;

				@include respond-to($tablet) {
					width: 50%;
					float: left;
				}

				.brand__quote__cite__image {
					width: 4rem;
					border-radius: 50%;
					margin-right: 1.5rem;
					overflow: hidden;

					img {
						max-width: 100%;
					}
				}

				.brand__quote__cite__name {
					font-size: 1.5rem;
				}

				.brand__quote__cite__caption {
					margin-top: 0.25rem;
				}
			}
		}

		.splide__pagination {
			top: 0;
			left: 0;
			bottom: auto;
			transform: none;
			justify-content: flex-start;
			z-index: 10;

			@include respond-to($tablet) {
				left: 2rem;
			}

			.splide__pagination__page {
				border-radius: 0;
				width: 2rem;
				height: 0.375rem;
				margin: 0 0.375rem;
				background-color: var(--footer-bg-color);

				&.is-active {
					background-color: var(--link-color);
					transform: none;
				}
			}
		}

		.splide__arrows {
			position: absolute;
			top: -11px;
			bottom: auto;
			right: 0;
			background-color: #ffffff;
			z-index: 10;

			display: flex;
			justify-content: space-between;

			@include respond-to($tablet) {
				top: auto;
				bottom: 3.75rem;
				padding-right: 5rem;
			}

			@include respond-to($desktop) {
				padding-right: 8rem;
			}

			button {
				position: static;
				transform: none;
				width: 3rem;
				height: 3rem;
				margin-left: 1rem;

				@include respond-to($tablet) {
					width: 4rem;
					height: 4rem;
					margin-left: 1.5rem;
				}

				background-color: transparent;
				border: 1px solid var(--border-color);

				transition: all 0.3s;

				&:hover {
					background-color: var(--link-color);
					border-color: var(--link-color);
					color: #fff;

					svg {
						fill: currentColor;
					}
				}
			}
		}
	}
}