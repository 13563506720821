.section.contact-form {
	.contact-form__inner {
		@include respond-to($tablet) {
			width: 85%;
		}

		@include respond-to($desktop) {
			width: 70%;
		}
	}

	h1 {
		font-size: 2.75rem;

		@include respond-to($tablet) {
			font-size: 4rem;
		}
	}

	.contact-form__content {
		font-size: 1.25rem;
		margin-top: 1.5rem;

		@include respond-to($tablet) {
			font-size: 1.5rem;
			margin-top: 2.5rem;
		}
	}

	.contact-form__form {
		height: 60vh;
		margin-top: 4rem;

		@include respond-to($tablet) {
			margin-top: 4rem;
		}

		.typeform-wrapper, .typeform-form {
			height: 100%;
		}

		.typeform-wrapper {
			position: relative;
			max-width: 840px;
			margin-left: -35px;
			overflow: hidden;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				height: 60px;
				width: 50%;
				background-color: #fff;

				@include respond-to($mobile) {
					display: none;
				}
			}
		}
	}

	.contact-form__form-submit {
		height: 60vh;
		margin-top: 4rem;

		@include respond-to($tablet) {
			margin-top: 4rem;
		}
	}
}