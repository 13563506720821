.section.homepage-services {
	background-color: #5600FF;
	color: #fff;

	.services__list {
		display: grid;
		grid-gap: 2rem;
		margin-top: 2rem;

		@include respond-to($tablet) {
			grid-template-columns: 1fr 1fr;
			margin-top: 4rem;
		}

		@include respond-to($desktop) {
			width: 85%;
			grid-gap: 2rem 5rem;
		}

		.services__item {
			font-size: 1.55rem;
			font-weight: 600;
			background-color: var(--primary-bg-color);
			color: var(--primary-fg-color);
			padding: 1rem 2rem;
		}
	}
}