.section.homepage-intro {
	.homepage-intro__inner {
		@include respond-to($tablet) {
			width: 85%;
		}

		@include respond-to($desktop) {
			width: 70%;
		}
	}

	h1 {
		font-size: 2.75rem;

		@include respond-to($tablet) {
			font-size: 4rem;
		}
	}

	.homepage-intro__content {
		font-size: 1.25rem;
		margin-top: 1.5rem;

		@include respond-to($tablet) {
			font-size: 1.5rem;
			margin-top: 2.5rem;
		}
	}

	.homepage-intro__metrics {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
		margin-top: 3rem;

		@include respond-to($tablet) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		.homepage-intro__metric {
			line-height: 1.1;

			.homepage-intro__metric__value {
				font-size: 2.75rem;
				font-weight: bold;
				margin-bottom: 0.5rem;

				@include respond-to($tablet) {
					font-size: 3rem;
				}
			}

			.homepage-intro__metric__label {
				font-size: 1.25rem;

				@include respond-to($tablet) {
					font-size: 1.5rem;
				}
			}
		}
	}
}