header {
	display: flex;
	align-items: center;
	height: 4.5rem;
	margin-top: 1rem;

	@include respond-to($tablet) {
		height: 5.625rem;
		margin-top: 1rem;
		margin-bottom: 2rem;
	}

	@include respond-to($desktop) {
		margin-top: 2rem;
	}

	.header__inner {
		display: grid;
		grid-template-columns: 8rem 1fr;
		grid-gap: 2rem;

		@include respond-to($tablet) {
			grid-template-columns: 11.25rem 1fr;
		}
	}

	.header__logo {
		display: flex;
		align-items: center;

		img {
			max-width: 100%;
			float: left;
		}
	}

	.header__navigation {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		a {
			@include underline($hasSpan: true);

			font-size: 1.5rem;
			padding: 0 1.5rem;

			display: none;

			@include respond-to($desktop) {
				display: block;
			}
		}

		button {
			margin-left: 1.5rem;

			@include respond-to($desktop) {
				font-size: 1.5rem;
			}
		}
	}
}
