.button {
	border: 0;
	background-color: transparent;
	padding: 1rem 2rem;

	&:hover {
		cursor: pointer;
	}
}

.button-cta {
	padding: 1rem 2rem;
}

.button-cta-link {
	font-size: 1.75rem;
	padding: 0;
	color: var(--link-color)
}

.button--primary {
	background-color: var(--primary-color);
	color: var(--primary-fg-color);
}

.button--white {
	background-color: #fff;
	color: var(--primary-fg-color);
}

.button--gradient {
	color: #fff;
	background: transparent linear-gradient(252deg, #00A2FF 0%, #8547FF 100%) 0% 0% no-repeat padding-box;
	background-size: 150% 120%;
	transition: all 0.3s;

	&:hover {
		background-position: 50% 10%;
		//background: transparent linear-gradient(108deg, #00A2FF 0%, #8547FF 100%) 0% 0% no-repeat padding-box;
	}
}