:root {
	--primary-color: #5600FF;

	--primary-bg-color: white;
	--primary-fg-color: black;

	--footer-bg-color: #101010;
	--footer-fg-color: #FCFCFC;

	--border-color: #d2d2d2;

	--link-color: #5600FF;

	--root-font-family: 'Rajdhani', sans-serif;
	--root-font-size: 16px;

	--size-gutter-lg: 30px;
	--size-breakpoint-lg: 1200px;
	--size-container-lg: 1140px;

	--size-gutter-md: 30px;
	--size-breakpoint-md: 992px;
	--size-container-md: 932px;
}