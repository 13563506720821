$small-mobile: '(max-width: 479px)';
$mobile-only: '(min-width: 480px) and (max-width: 767px)';
$mobile: '(max-width: 767px)';
$tablet-only: '(min-width: 768px) and (max-width: 991px)';
$tablet: '(min-width: 768px)';
$tablet-and-mobile: '(max-width: 991px)';
$desktop: '(min-width: 992px)';
$large-desktop: '(min-width: 1200px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)';

@mixin respond-to($media) {
	@media #{$media} {
		@content;
	}
}