.footer {
	background-color: var(--footer-bg-color);
	color: var(--footer-fg-color);
	padding: 4rem 0 4rem;

	@include respond-to($desktop) {
		padding: 6rem 0 4rem;
	}

	.footer__inner {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 3rem;

		@include respond-to($desktop) {
			grid-template-columns: 0.75fr 1fr;
			grid-gap: 7.5rem;
		}
	}

	.footer__copyright {
		display: grid;
		margin-top: 4.5rem;
		grid-template-columns: 1fr;

		@include respond-to($desktop) {
			margin-top: 7.5rem;
			grid-template-columns: 11.25rem 1fr;
		}
	}

	.footer__copyright {
		.footer__copyright__logo {
			max-width: 180px;
			opacity: 0.25;

			@include respond-to($desktop) {
				max-width: none;
			}

			img {
				max-width: 100%;
				height: auto;
			}
		}

		.footer__copyright__text {
			display: flex;
			align-items: center;
			opacity: 0.375;
			margin-top: 0.5rem;

			@include respond-to($desktop) {
				justify-content: flex-end;
				text-align: right;
				margin-top: 0;
			}
		}
	}

	.footer__navigation	{
		margin-bottom: 2rem;

		@include respond-to($desktop) {
			margin-bottom: 0;
		}

		.footer__navigation__item {
			font-size: 2rem;

			display: block;
			position: relative;
			padding: 0.5rem 1.875rem;
			margin-bottom: 1rem;
			border-bottom: 2px solid;
			opacity: 0.25;
			transition: all 0.3s;

			&:last-child {
				margin-bottom: 0;
			}

			@include respond-to($desktop) {
				font-size: 2.75rem;
				padding: 0.75rem 2.25rem;
			}

			&:before {
				font-size: 1rem;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				content: attr(data-index);

				@include respond-to($desktop) {
					font-size: 1.25rem;
				}
			}

			&:hover, &.active {
				opacity: 1;
			}
		}
	}

	.footer__contact {
		.footer__contact__title {
			font-size: 3.25rem;
			line-height: 1.1;

			@include respond-to($desktop) {
				font-size: 6rem;
			}
		}

		.footer__contact__inner {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 1rem;
			margin-top: 2rem;

			@include respond-to($desktop) {
				grid-template-columns: 1.5fr 1fr;
				grid-gap: 1rem;
			}
		}

		.footer__contact__email {
			.footer__contact__email__title {
				font-size: 1.5rem;
				font-weight: bold;
				opacity: 0.25;
				margin-bottom: 0.5rem;

				@include respond-to($desktop) {
					font-size: 1.75rem;
					margin-bottom: 1rem;
				}
			}

			.footer__contact__email__text {
				font-size: 1.25rem;

				@include respond-to($desktop) {
					font-size: 1.5rem;
				}
			}

			.footer__contact__email__submit {
				margin-top: 2rem;
				width: 100%;

				.button {
					min-width: 220px;
				}
			}
		}

		.footer__contact__company__menu {
			list-style: none;
			margin: 4rem 0 0;
			padding: 0.5rem 0 0;

			@include respond-to($desktop) {
				margin: 0;
			}

			li {
				font-size: 1.25rem;
				margin-bottom: 0.75rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}